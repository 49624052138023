import { Link } from 'react-router-dom';
import {useContext} from "react";
import {AuthContext} from "../auth/AuthContext";

function Nav() {
    const { logout } = useContext(AuthContext)

    const onLogout = () => {
        logout()
        window.location.reload()
    }

    return (
        <header className="navbar navbar-expand-md d-print-none mb-5">
            <div className="container-xl">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu"
                        aria-controls="navbar-menu" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
                    <a href="#">
                        {/*<img src="assets/css/images/esqque-isotipo-white.png" width="60" alt="esqque isotipo"/>*/}
                        ADMIN
                    </a>
                </h1>

                <div className="navbar-nav flex-row order-md-last">
                    <div className="d-none d-md-flex">
                        <a href="?theme=dark" className="nav-link px-0 hide-theme-dark"
                           title="Enable dark mode" data-bs-toggle="tooltip"
                           data-bs-placement="bottom">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                 strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path
                                    d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z"/>
                            </svg>
                        </a>
                        <a href="?theme=light" className="nav-link px-0 hide-theme-light"
                           title="Enable light mode" data-bs-toggle="tooltip"
                           data-bs-placement="bottom">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                 strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"/>
                                <path
                                    d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"/>
                            </svg>
                        </a>
                    </div>
                    <div className="nav-item dropdown">
                        <a href="#" className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown"
                           aria-label="Open user menu">
                            <span id="property-owner-avatar" className="avatar avatar-sm"></span>
                            <div className="d-none d-xl-block ps-2">
                                <div id="property-owner-name"></div>
                            </div>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                            <a href="#" className="dropdown-item" onClick={onLogout}>Logout</a>
                         </div>
                    </div>
                </div>

                <div className="collapse navbar-collapse" id="navbar-menu">
                    <div className="d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link" href="./">
                                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24" fill="none"
                                             stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                                             strokeLinejoin="round"
                                             className="icon"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path
                                            d="M5 12l-2 0l9 -9l9 9l-2 0"></path><path
                                            d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path><path
                                            d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path></svg>
                                    </span>
                                    <span className="nav-link-title">
                                      Dashboard
                                    </span>
                                </a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#navbar-help" data-bs-toggle="dropdown"
                                   data-bs-auto-close="outside" role="button" aria-expanded="false">
                                    <span className="nav-link-icon d-md-none d-lg-inline-block">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                         className="icon icon-tabler icons-tabler-outline icon-tabler-file"><path stroke="none" d="M0 0h24v24H0z"
                                                                                                                  fill="none"/><path
                                        d="M14 3v4a1 1 0 0 0 1 1h4"/><path
                                        d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"/></svg>
                                    </span>
                                    <span className="nav-link-title">Documents</span>
                                </a>
                                <div className="dropdown-menu">
                                    <Link className="dropdown-item" to="documents/partners">Partners</Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </header>
    );
}

export default Nav;